import React, { useEffect, useState } from "react";
import { useChatState } from "../Context/ChatProvider";
import {
  Box,
  FormControl,
  IconButton,
  Input,
  Spinner,
  Text,
  useToast,
} from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { getSender, getSenderFull } from "../config/ChatLogics";
import ProfileModal from "./miscellaneous/ProfileModal";
import UpdateGroupChatModal from "./miscellaneous/UpdateGroupChatModal";
import axios from "axios";
import ScrollableChat from "./ScrollableChat";
import io from "socket.io-client";
import Lottie from 'react-lottie';
import animationData from "../animations/typing.json";
const ENDPOINT=`${process.env.REACT_APP_BACKEND_URL}`;
var socket, selectedChatCompare;
const SingleChat = ({ fetchAgain, setFetchAgain }) => {
  const [loading, setLoading] = useState(false);
  const [newMessage, setNewMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [socketConnected, setSocketConnected]= useState(false);
  const [typing, setTyping]= useState(false);
  const [isTyping, setIsTyping]= useState(false);
  const { user, selectedChat, setSelectedChat } = useChatState();
  const toast = useToast();
  const defaultOptions={
    loop:true,
    autoplay:true,
    animationData: animationData,
    rendererSettings:{
      preserveAspectRatio:"xMidYMid slice"
    }
  }

  const sendMessage = async (event) => {
    if (event.key === "Enter" && newMessage) {
      socket.emit("stop typing", selectedChat._id);
    
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`, // и�п�авлено
          },
        };

        setNewMessage("");
        const { data } = await axios.post(
          "/api/message",
          {
            content: newMessage,
            chatId: selectedChat._id,
          },
          config
        );

        console.log(data);
        socket.emit("new message", data); // о�п�авка нового �ооб�ени� по �оке��
        setMessages((prevMessages) => [...prevMessages, data]); // добавление �ооб�ени� в �о��о�ние
      } catch (error) {
        toast({
          title: "Error Occurred!",
          description: "Failed to send the Message",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "bottom",
        });
      }
    }
  };

  useEffect(() => {
    socket = io(ENDPOINT);

    socket.emit("setup", user);

    socket.on("connected", () => {
      setSocketConnected(true);
    });

    socket.on("typing", () => setIsTyping(true));
    socket.on("stop typing", () => setIsTyping(false));
    // Сл��ай �ооб�ени� ��аз� по�ле ини�иализа�ии �оке�а
    socket.on("message received", (newMessageReceived) => {
      if (!selectedChatCompare || selectedChatCompare._id !== newMessageReceived.chat._id) {
      // �озможно, ��ои� обнови�� �о��о�ние �ооб�ени� зде��
	setFetchAgain((prev) => !prev); // Э�о п�иведе� к пов�о�ной заг��зке �ооб�ений
      } else {
	setMessages((prevMessages) => [...prevMessages, newMessageReceived]); // �обав� новое �ооб�ение в �о��о�ние
      }
    });

    // ��и��ка п�и �азмон�и�овании
    return () => {
	socket.disconnect();
    };
  }, []);

  const fetchMessages = async () => {
    if (!selectedChat) {
      return;
    }
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };
      setLoading(true);
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/message/${selectedChat._id}`,
        config
      );
      console.log(messages);
      setMessages(data);
      setLoading(false);
      socket.emit("join chat", selectedChat._id);
    } catch (error) {
      toast({
        title: "Error Occured!",
        description: "Failed to load the messages",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom",
      });
    }
  };
  useEffect(() => {
    fetchMessages();
    selectedChatCompare= selectedChat;
  }, [selectedChat]);
  useEffect(()=>{
    socket.on("message received",(newMessageReceived)=>{
      if(!selectedChatCompare || selectedChatCompare._id!== newMessageReceived.chat._id){

      }
      else{
        setMessages([...messages, newMessageReceived]);
      }
    })
  })
  const typingHandler = (e) => {
    setNewMessage(e.target.value);
    //Typing indicator logic
    if(!socketConnected) return;
    if(!typing){
      setTyping(true);
    }
    socket.emit("typing", selectedChat._id);
    let lastTypingTime= new Date().getTime();
    var timeLength=3000;
    setTimeout(() => {
      var timeNow= new Date().getTime();
      var timeDiff= timeNow- lastTypingTime;
      if(timeDiff>=timeLength && typing){
        socket.emit("stop typing", selectedChat._id);
        setTyping(false);
      }
    }, timeLength);
  };
  return (
    <>
      {selectedChat ? (
        <>
          <Text
            fontSize={{ base: "28px", md: "30px" }}
            pb={3}
            px={2}
            w={"100%"}
            fontFamily={"Work sans"}
            display={"flex"}
            justifyContent={{ base: "space-between" }}
            alignItems={"center"}
          >
            <IconButton
              display={{ base: "flex", md: "none" }}
              icon={<ArrowBackIcon />}
              onClick={() => setSelectedChat("")}
            />
            {!selectedChat.isGroupChat ? (
              <>
                {getSender(user, selectedChat.users)}
                <ProfileModal user={getSenderFull(user, selectedChat.users)} />
              </>
            ) : (
              <>
                {selectedChat.chatName.toUpperCase()}
                <UpdateGroupChatModal
                  fetchAgain={fetchAgain}
                  setFetchAgain={setFetchAgain}
                  fetchMessages={fetchMessages}
                />
              </>
            )}
          </Text>
          <Box
            display={"flex"}
            flexDir={"column"}
            justifyContent={"flex-end"}
            p={3}
            bg={"#E8E8E8"}
            w={"100%"}
            h={"100%"}
            borderRadius={"lg"}
            overflowY={"hidden"}
          >
            {loading ? (
              <Spinner
                size={"xl"}
                w={20}
                h={20}
                alignSelf={"center"}
                margin={"auto"}
              />
            ) : (
              <div>
                <ScrollableChat messages={messages}/>
              </div>
            )}
            <FormControl onKeyDown={sendMessage} isRequired mt={3}>
              {isTyping?<div>
                <Lottie options={defaultOptions} width={70} style={{marginBottom:15, marginLeft:0}}/>
              </div>:(<></>)}
              <Input
                variant={"filled"}
                bg={"#E0E0E0"}
                placeholder="Enter a message.."
                onChange={typingHandler}
                value={newMessage}
              />
            </FormControl>
          </Box>
        </>
      ) : (
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          h={"100%"}
        >
          <Text fontSize={"3xl"} pb={3} fontFamily={"Work sans"}>
            Click on a user to start chatting
          </Text>
        </Box>
      )}
    </>
  );
};

export default SingleChat;
